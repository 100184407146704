import React, { useEffect } from 'react';
import $ from 'jquery';
import '../css/MainContent.css';

function MainContent({ selectedFile }) {
  useEffect(() => {
    const timestamp = new Date().getTime(); // 現在のタイムスタンプを取得
    const fileWithTimestamp = `${selectedFile}?v=${timestamp}`; // ファイルURLにタイムスタンプを追加

    $("#home-content").load(fileWithTimestamp, function(response, status, xhr) {
      if (status === "error") {
        console.log("Error loading default file: ", xhr.status, xhr.statusText);
      }
    });
  }, [selectedFile]);
  // useEffect(() => {
  //   const loadContent = () => {
  //     $("#home-content").load(selectedFile, function(response, status, xhr) {
  //       if (status === "error") {
  //         console.log("Error loading default file: ", xhr.status, xhr.statusText);
  //       }
  //     });
  //   };

  //   loadContent();

  //   return () => {
  //     // クリーンアップ関数を追加
  //     $("#home-content").off("load");
  //   };
  // }, [selectedFile]);

  return (
    <div id="home-content" className="content">
      {/* メインコンテンツ */}
    </div>
  );
}

export default MainContent;
