import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../css/Header.css';

function Header({ toggleIconTray, iconTrayVisible }) {
  const [scrollY, setScrollY] = useState(0);
  const [headerHidden, setHeaderHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > scrollY) {
        setHeaderHidden(true);
      } else {
        setHeaderHidden(false);
      }
      setScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);

  useEffect(() => {
    // テーマ切り替え機能
    const checkbox = document.getElementById('checkbox');
    const body = document.body;

    // チェックボックスの状態が変更されたとき
    const handleCheckboxChange = () => {
      if (checkbox.checked) {
        body.classList.remove('light-mode');
        body.classList.add('dark-mode');
        window.localStorage.setItem('theme', 'dark'); // ダークモードをローカルストレージに保存
      } else {
        body.classList.remove('dark-mode');
        body.classList.add('light-mode');
        window.localStorage.setItem('theme', 'light'); // ライトモードをローカルストレージに保存
      }
    };

    // 初期状態の設定
    const initializeTheme = () => {
      const savedTheme = window.localStorage.getItem('theme');
      if (savedTheme === 'dark') {
        checkbox.checked = true;
        body.classList.remove('light-mode');
        body.classList.add('dark-mode');
      } else {
        checkbox.checked = false;
        body.classList.remove('dark-mode');
        body.classList.add('light-mode');
      }
    };

    // 初期テーマ設定
    initializeTheme();

    // チェックボックスのイベントリスナーを追加
    checkbox.addEventListener('change', handleCheckboxChange);

    // クリーンアップ
    return () => {
      checkbox.removeEventListener('change', handleCheckboxChange);
    };
  }, []);

  return (
    <header className={`header ${headerHidden ? 'hidden' : ''}`}>
      <div className="theme-switch-wrapper">
        <label className="theme-switch" htmlFor="checkbox">
          <input type="checkbox" id="checkbox" />
          <div className="slider round">
            <FontAwesomeIcon icon={faSun} className="sun-icon" />
            <FontAwesomeIcon icon={faMoon} className="moon-icon" />
          </div>
        </label>
      </div>
      <button className="toggle-switch" onClick={() => toggleIconTray(!iconTrayVisible)}>
        <FontAwesomeIcon icon={iconTrayVisible ? faTimes : faBars} className="icon" />
      </button>
      {/* <div className="icon-tray-toggle" onClick={toggleIconTray}>
        <FontAwesomeIcon icon={iconTrayVisible ? faTimes : faBars} />
      </div> */}
    </header>
  );
}

export default Header;