import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import '../css/MainContent.css';
import '../css/BackToTop.css';

function Footer() {
  const [showButton, setShowButton] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    const backToTopButton = document.querySelector('.back-to-top');
    if (showButton) {
      backToTopButton.style.display = 'block';
      setTimeout(() => {
        backToTopButton.style.opacity = 1;
      }, 100);
    } else {
      backToTopButton.style.opacity = 0;
      setTimeout(() => {
        backToTopButton.style.display = 'none';
      }, 400); // CSSのtransitionと同じ時間
    }
  }, [showButton]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <footer></footer>
      <button className="back-to-top" onClick={scrollToTop}>
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
    </>
  );
}

export default Footer;
