import React from 'react';
import '../css/MainContent.css';
import '../css/AboutContent.css';

function AboutContent() {
  return (
    <div id="about-content" className="content">
      <div className="about-flex">
        <div className="profile-section">
        <img src="/images/profile.jpg" alt="Profile Photo" className="profile-img" id="profile-image" />
          <p className="photo-caption">Автор, на мосту Шохей(Фотография Минору Сайто, Токио, ноябрь 2013 г.)</p>
        </div>
        <div className="career">
          <h2>Карьера</h2>
          <table>
            <tr>
              <td><i>8 января 1986 г.</i></td>
              <td>рождение</td>
            </tr>
            <tr>
              <td><i>февраль 2013 г.</i></td>
              <td>Виола играет на улицах Токио</td>
            </tr>
            <tr>
              <td><i>января 2024 г.</i></td>
              <td>Начните изучать программирование с использованием ИИ</td>
            </tr>
            <tr>
              <td><i>Июль 2024 г.</i></td>
              <td><a href="https://abehiroto.com/wmapp">"Одержимый арбузом"("Obsessed with Watermelon")</a>выпускать（Go + Flutter）</td>
            </tr>
            <tr>
              <td><i>ноябрь 2024 г.</i></td>
              <td>Оформить вид на жительство в (неизвестное местоположение)</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AboutContent;
