import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faEnvelope, faUser, faCopyright, faClock } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import '../css/IconTray.css';

function IconTray({ setActiveContent, toggleSidebar, visible }) {
    useEffect(() => {
      const menuIcon = document.getElementById('menu-icon');
      const iconTray = document.querySelector('.icon-tray');
      const copyrightIcon = document.getElementById('copyright-icon');
      const copyrightModal = document.getElementById('copyrightModal');
      const closeButton = document.querySelector('.close-copyright-button');
      // アイコントレーのアニメーションが完了した後にtoggleSidebarを処理しないと動作しない
      const handleMenuClick = () => {
        iconTray.classList.remove('visible');
        iconTray.classList.add('hidden');
        setTimeout(() => {
            toggleSidebar(true);
        }, 160); // アニメーションが完了した後に非表示
      };

      // サイドバー開閉に連動したアイコントレーの表示切り替えで引っかかったとこ下
      // const handleMenuClick = () => {
      //   iconTray.classList.remove('visible');
      //   iconTray.classList.add('hidden');
      //   toggleSidebar(true); // サイドバーを表示
      // };
      // const handleMenuClick = () => {
      //   // iconTray.classList.add('hidden');
      //   setTimeout(() => {
      //     iconTray.style.display = 'none';
      //     toggleSidebar(true); // サイドバーを表示
      //   }, 300); // アニメーションが完了した後に非表示
      // };
  
      menuIcon.addEventListener('click', handleMenuClick);
      // // コピーライトモーダルのイベントリスナーを追加
      // const handleCopyrightClick = () => {
      //     copyrightModal.style.display = 'block';
      // };

      const handleCloseClick = () => {
          copyrightModal.style.display = 'none';
      };

      const handleOutsideClick = (event) => {
          if (event.target === copyrightModal) {
            copyrightModal.style.display = 'none';
          }
      };
      // // コピーライトモーダルを使う場合アクティベート
      // copyrightIcon.addEventListener('click', handleCopyrightClick);
      closeButton.addEventListener('click', handleCloseClick);
      window.addEventListener('click', handleOutsideClick);
  
      return () => {
          menuIcon.removeEventListener('click', handleMenuClick);
          // // コピーライトモーダルを使う場合アクティベート
          // copyrightIcon.removeEventListener('click', handleCopyrightClick);
          closeButton.removeEventListener('click', handleCloseClick);
          window.removeEventListener('click', handleOutsideClick);
      };
    }, [toggleSidebar]);

  return (
    <div className={`icon-tray ${visible ? 'visible' : 'hidden'}`}>
      <div className="hamburger-menu">
        <FontAwesomeIcon icon={faClock} id="menu-icon" className="icon" />
      </div>
      <div className="separator"></div>
      <div className="social-links">
        <a id="home-icon" href="#" onClick={() => setActiveContent('home')}><FontAwesomeIcon icon={faHome} className="icon" /></a>
        <a id="watermelon-icon" href="https://abehiroto.com/wmapp" target="_blank">
          <img src="images/suika3.svg" alt="Watermelon Icon" className="social-icon" />
        </a>
        <a href="https://github.com/AbeHiroto" target="_blank"><FontAwesomeIcon icon={faGithub} className="icon" /></a>
        {/* <a href="https://www.youtube.com/channel/UCGErJLFL1DHR7q0tjUEzs5w" target="_blank"><FontAwesomeIcon icon={faYoutube} className="icon" /></a> */}
        {/* <a href="https://twitter.com/AbeHirotoX" target="_blank"><FontAwesomeIcon icon={faTwitter} className="icon" /></a> */}
        <a id="contact-icon" href="#" onClick={() => setActiveContent('contact')}><FontAwesomeIcon icon={faEnvelope} className="icon" /></a>
        <a id="about-icon" href="#" onClick={() => setActiveContent('about')}><FontAwesomeIcon icon={faUser} className="icon" /></a>
        {/* <a id="copyright-icon" href="#"><FontAwesomeIcon icon={faCopyright} className="icon" /></a> */}
      </div>
      {/* <div id="copyrightModal" className="copyright-modal">
        <div className="copyright-modal-content">
          <span className="close-copyright-button">&times;</span>
          <p>© 2024 by 阿部大登 (Hiroto Abe). All rights reserved.</p>
        </div>
      </div> */}
    </div>
  );
}

export default IconTray;
